import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { FileDownloadOutlined } from '@mui/icons-material'
import { Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

import type { DocumentProps } from '@/types'
import { useT } from '@/utils/frontend/useT'
import generateDownloadURL from '@/utils/plain/generateDownloadURL'

type FirstViewportDownloadListProps = {
  documents: DocumentProps[]
  onItemClick: (document: DocumentProps, target: string) => void
} & QaHook

export const FirstViewportDownloadList = (props: FirstViewportDownloadListProps) => {
  const { documents, onItemClick, dataCy } = props

  const { t } = useT({ keyPrefix: 'product.files' })

  return (
    <List
      disablePadding
      dense
      data-cy={`${dataCy}-firstViewportDownloadList`}
      sx={{ border: '0px' }}
    >
      {documents?.map((item) => {
        const href = generateDownloadURL(item)
        const downloadProps = {
          href,
          target: '_blank',
          rel: 'noopener',
          onClick: () => onItemClick(item, href),
        }

        return (
          <ListItem
            key={item.id}
            dense
            disableGutters
            sx={{ py: '8px' }}
            data-cy={`${dataCy}-firstViewportDownloadListItem-${item.id}`}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>
              <Link
                {...downloadProps}
                download
                underline="none"
                sx={{ display: 'flex' }}
                aria-label={t('actions.download')}
              >
                <FileDownloadOutlined color="secondary" />
              </Link>
            </ListItemIcon>
            <ListItemText
              sx={{ my: 0 }}
              primary={
                <Link underline="hover" color="inherit" {...downloadProps}>
                  <Typography variant="body3dense">{item.name}</Typography>
                </Link>
              }
            />
          </ListItem>
        )
      })}
    </List>
  )
}
