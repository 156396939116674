import { useState } from 'react'

import { RTLIconStyles } from '@knauf-group/ct-designs/utils/utils'
import { ArrowForward } from '@mui/icons-material'
import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material'

import { CERTIFICATE_HREF, DOWNLOAD_HREF } from '@/constants'
import { useProduct } from '@/providers/p/ProductProvider'
import type { DocumentProps } from '@/types'
import { useProductDetailAnalyticsTracking } from '@/utils/frontend/analytics/useProductDetailAnalyticsTracking'
import { useCurrentUrlWithoutHashtag } from '@/utils/frontend/useBaseUrl'
import { useT } from '@/utils/frontend/useT'
import { isEmpty } from '@/utils/plain/isEmpty'

import { FirstViewportDownloadList } from './FirstViewportDownloadList'

const dataCy = 'FirstViewportDownloadSection'

const FirstViewportDownloadSection = () => {
  const { product } = useProduct()
  const { firstViewportDocuments } = product

  const { t } = useT()

  const { trackDownload, trackClickEvent } = useProductDetailAnalyticsTracking()

  const currentUrl = useCurrentUrlWithoutHashtag()

  const languageOptions = Object.keys(firstViewportDocuments)

  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLanguage(event.target.value)
  }

  const documents = firstViewportDocuments[selectedLanguage]
  // firstViewportDocuments contain both documents and certificates
  const onlyDocuments = documents.filter((doc) => doc.category !== 'certificate')

  if (isEmpty(documents)) return null

  const downloadButtonHref =
    documents.length > 0 && onlyDocuments?.length === 0 ? CERTIFICATE_HREF : DOWNLOAD_HREF

  const onItemClick = (document: DocumentProps, target: string) => {
    trackDownload({
      action: document.name,
      files: [document],
      language: selectedLanguage,
      target,
    })
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="body2bold" data-cy={`${dataCy}-title`}>
          {t('product.downloads-title')}
        </Typography>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label id="select-language-label" style={{ display: 'none' }}>
          {t('common.footer.select-language')}
        </label>
        <TextField
          select
          value={selectedLanguage}
          onChange={handleChange}
          data-cy={`${dataCy}-languageSelector`}
          inputProps={{
            'aria-labelledby': 'select-language-label',
            'aria-label': t('common.footer.select-language'),
          }}
        >
          {languageOptions.map((language) => (
            <MenuItem
              key={language}
              value={language}
              data-cy={`${dataCy}-languageSelector-item-${language}`}
            >
              {language?.toUpperCase()}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <FirstViewportDownloadList
        documents={documents}
        dataCy={dataCy}
        onItemClick={onItemClick}
      />

      <Button
        variant="contained"
        size="medium"
        color="primary"
        component="a"
        data-cy={`${dataCy}-downloads-button`}
        href={downloadButtonHref}
        onClick={() =>
          trackClickEvent(
            t('product.downloads-button-text'),
            currentUrl + downloadButtonHref,
            'internal_cta_click',
          )
        }
        sx={{
          width: {
            xs: '100%',
            lg: 'fit-content',
          },
        }}
        endIcon={<ArrowForward sx={RTLIconStyles} />}
      >
        {t('product.downloads-button-text')}
      </Button>
    </Stack>
  )
}

export default FirstViewportDownloadSection
